@font-face {
  font-family: 'icomoon';
  src: url("/fonts/icomoon.eot?8ekcno");
  src: url("/fonts/icomoon.eot?8ekcno#iefix") format("embedded-opentype"), url("/fonts/icomoon.ttf?8ekcno") format("truetype"), url("/fonts/icomoon.woff?8ekcno") format("woff"), url("/fonts/icomoon.svg?8ekcno#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-search:before {
  content: "\f002"; }

.icon-exclamation-circle:before {
  content: "\f06a"; }

.icon-exclamation-triangle:before {
  content: "\f071"; }

.icon-warning:before {
  content: "\f071"; }

.icon-file-text-o:before {
  content: "\f0f6"; }

.icon-file-word-o:before {
  content: "\f1c2"; }

.icon-file-powerpoint-o:before {
  content: "\f1c4"; }

.icon-file-excel-o:before {
  content: "\f1c3"; }

.icon-lock:before {
  content: "\f023"; }

.icon-user:before {
  content: "\f007"; }

.icon-check:before {
  content: "\f00c"; }

.icon-close:before {
  content: "\f00d"; }

.icon-remove:before {
  content: "\f00d"; }

.icon-times:before {
  content: "\f00d"; }

.icon-file-o:before {
  content: "\f016"; }

.icon-download:before {
  content: "\f019"; }

.icon-question-circle:before {
  content: "\f059"; }

.icon-plus:before {
  content: "\f067"; }

.icon-minus:before {
  content: "\f068"; }

.icon-upload:before {
  content: "\f093"; }

.icon-group:before {
  content: "\f0c0"; }

.icon-users:before {
  content: "\f0c0"; }

.icon-envelope:before {
  content: "\f0e0"; }

.icon-file-pdf-o:before {
  content: "\f1c1"; }

.icon-file-image-o:before {
  content: "\f1c5"; }

.icon-file-photo-o:before {
  content: "\f1c5"; }

.icon-file-picture-o:before {
  content: "\f1c5"; }

.icon-file-archive-o:before {
  content: "\f1c6"; }

.icon-file-zip-o:before {
  content: "\f1c6"; }

.icon-file-audio-o:before {
  content: "\f1c7"; }

.icon-file-sound-o:before {
  content: "\f1c7"; }

.icon-file-movie-o:before {
  content: "\f1c8"; }

.icon-file-video-o:before {
  content: "\f1c8"; }

.icon-share-alt:before {
  content: "\f1e0"; }

.icon-trash:before {
  content: "\f1f8"; }

.icon-spinner:before {
  content: "\e93c"; }

.icon-assets .path1:before {
  content: "\e900";
  color: #028da7; }

.icon-assets .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: #037484; }

.icon-assets .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: #037484; }

.icon-assets .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: #037484; }

.icon-assets .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: white; }

.icon-assets .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: white; }

.icon-assets .path7:before {
  content: "\e906";
  margin-left: -1em;
  color: white; }

.icon-assets .path8:before {
  content: "\e907";
  margin-left: -1em;
  color: white; }

.icon-assets .path9:before {
  content: "\e908";
  margin-left: -1em;
  color: white; }

.icon-assets .path10:before {
  content: "\e909";
  margin-left: -1em;
  color: white; }

.icon-assets .path11:before {
  content: "\e90a";
  margin-left: -1em;
  color: #006771; }

.icon-assets .path12:before {
  content: "\e90b";
  margin-left: -1em;
  color: #0097a6; }

.icon-assets .path13:before {
  content: "\e90c";
  margin-left: -1em;
  color: #0097a6; }

.icon-assets .path14:before {
  content: "\e90d";
  margin-left: -1em;
  color: #0097a6; }

.icon-assets .path15:before {
  content: "\e90e";
  margin-left: -1em;
  color: white; }

.icon-assets .path16:before {
  content: "\e90f";
  margin-left: -1em;
  color: white; }

.icon-assets .path17:before {
  content: "\e910";
  margin-left: -1em;
  color: white; }

.icon-assets .path18:before {
  content: "\e911";
  margin-left: -1em;
  color: #028da7; }

.icon-assets .path19:before {
  content: "\e912";
  margin-left: -1em;
  color: #028da7; }

.icon-assets .path20:before {
  content: "\e913";
  margin-left: -1em;
  color: #028da7; }

.icon-documents .path1:before {
  content: "\e914";
  color: #028da7; }

.icon-documents .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: #037484; }

.icon-documents .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: #028da7; }

.icon-documents .path4:before {
  content: "\e917";
  margin-left: -1em;
  color: white; }

.icon-documents .path5:before {
  content: "\e918";
  margin-left: -1em;
  color: white; }

.icon-documents .path6:before {
  content: "\e919";
  margin-left: -1em;
  color: white; }

.icon-documents .path7:before {
  content: "\e91a";
  margin-left: -1em;
  color: white; }

.icon-documents .path8:before {
  content: "\e91b";
  margin-left: -1em;
  color: white; }

.icon-documents .path9:before {
  content: "\e91c";
  margin-left: -1em;
  color: white; }

.icon-documents .path10:before {
  content: "\e91d";
  margin-left: -1em;
  color: white; }

.icon-documents .path11:before {
  content: "\e91e";
  margin-left: -1em;
  color: white; }

.icon-epa .path1:before {
  content: "\e91f";
  color: #028da7; }

.icon-epa .path2:before {
  content: "\e920";
  margin-left: -1em;
  color: white; }

.icon-epa .path3:before {
  content: "\e921";
  margin-left: -1em;
  color: #037484; }

.icon-epa .path4:before {
  content: "\e922";
  margin-left: -1em;
  color: white; }

.icon-epa .path5:before {
  content: "\e923";
  margin-left: -1em;
  color: white; }

.icon-epa .path6:before {
  content: "\e924";
  margin-left: -1em;
  color: white; }

.icon-epa .path7:before {
  content: "\e925";
  margin-left: -1em;
  color: #037484; }

.icon-insurance .path1:before {
  content: "\e926";
  color: #028da7; }

.icon-insurance .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: #037484; }

.icon-insurance .path3:before {
  content: "\e928";
  margin-left: -1em;
  color: white; }

.icon-trusts .path1:before {
  content: "\e929";
  color: #028da7; }

.icon-trusts .path2:before {
  content: "\e92a";
  margin-left: -1em;
  color: #037484; }

.icon-trusts .path3:before {
  content: "\e92b";
  margin-left: -1em;
  color: white; }

.icon-trusts .path4:before {
  content: "\e92c";
  margin-left: -1em;
  color: white; }

.icon-trusts .path5:before {
  content: "\e92d";
  margin-left: -1em;
  color: white; }

.icon-trusts .path6:before {
  content: "\e92e";
  margin-left: -1em;
  color: #028da7; }

.icon-trusts .path7:before {
  content: "\e92f";
  margin-left: -1em;
  color: #037484; }

.icon-will .path1:before {
  content: "\e930";
  color: #028da7; }

.icon-will .path2:before {
  content: "\e931";
  margin-left: -1em;
  color: #037484; }

.icon-will .path3:before {
  content: "\e932";
  margin-left: -1em;
  color: #028da7; }

.icon-will .path4:before {
  content: "\e933";
  margin-left: -1em;
  color: white; }

.icon-will .path5:before {
  content: "\e934";
  margin-left: -1em;
  color: #037484; }

.icon-will .path6:before {
  content: "\e935";
  margin-left: -1em;
  color: white; }

.icon-will .path7:before {
  content: "\e936";
  margin-left: -1em;
  color: #037484; }

.icon-will .path8:before {
  content: "\e937";
  margin-left: -1em;
  color: white; }

.icon-will .path9:before {
  content: "\e938";
  margin-left: -1em;
  color: white; }

.icon-will .path10:before {
  content: "\e939";
  margin-left: -1em;
  color: white; }

.icon-will .path11:before {
  content: "\e93a";
  margin-left: -1em;
  color: white; }

.icon-will .path12:before {
  content: "\e93b";
  margin-left: -1em;
  color: white; }

.icon-will, .icon-epa, .icon-trusts, .icon-insurance, .icon-documents, .icon-assets {
  font-size: 50px; }

.spin {
  animation: spin 1s infinite linear;
  display: inline-block; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.vault.loading {
  padding: 100px 0;
  text-align: center;
  font-size: 30px; }

.vault a {
  text-decoration: none; }

.alert [class^="icon-"], .alert [class*=" icon-"] {
  float: left;
  font-size: 20px;
  margin: 0 10px 5px 0; }

.alert.error {
  background: #E67825; }

.vault section {
  padding: 15px;
  border: 1px solid #9fd5df;
  color: #585a5c;
  background: #9fd5df;
  border-radius: 5px;
  margin: 0 0 10px; }
  .vault section.open {
    padding: 10px 15px 15px;
    border: 1px solid #018da7;
    background: none; }
    .vault section.open > .row:first-child {
      margin: 0 0 20px; }
    .vault section.open h3 {
      font-size: 14px;
      line-height: 20px;
      font-weight: bold; }
      .vault section.open h3 [class^="icon-"], .vault section.open h3 [class*=" icon-"] {
        font-size: 28px;
        line-height: 20px; }
  .vault section .info {
    margin: 15px 0 0; }
  .vault section label.upload {
    float: right;
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
    cursor: pointer; }
    .vault section label.upload:hover {
      color: #018da7; }
    .vault section label.upload [class^="icon-"], .vault section label.upload [class*=" icon-"] {
      margin-left: 8px;
      font-size: 14px; }
  .vault section.drag {
    border: 1px dashed #E67825;
    background: none; }

.document {
  max-width: inherit;
  font-size: 12px;
  line-height: 20px;
  min-height: 24px;
  cursor: pointer;
  position: relative;
  vertical-align: top;
  border-radius: 12px;
  margin: 0 -8px 1px;
  padding: 2px 10px;
  transition: background 0.15s ease 0.05s;
  overflow: hidden; }
  .document:hover {
    background: #9fd5df;
    transition: all 0.15s ease 0s; }
  .document.active {
    background: #018da7;
    color: #f0f0f0;
    border-radius: 5px; }
  .document.active [class^="icon-"], .document.active [class*=" icon-"] {
    color: #f0f0f0; }
  .document.active .document-actions [class^="icon-"]:hover, .document.active .document-actions [class*=" icon-"]:hover {
    color: #585a5c; }
  .document .extension {
    font-size: 14px;
    line-height: 20px;
    margin-right: 5px;
    float: left; }
  .document .filename {
    font-size: 14px; }
  .document .desc {
    opacity: 0.7;
    font-size: 11px; }
  .document [class^="icon-"], .document [class*=" icon-"] {
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    text-align: right;
    margin-left: 8px;
    color: #585a5c; }
  .document [class^="icon-"]:first-child, .document [class*=" icon-"]:first-child {
    margin-left: 0; }

.text-wrap {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 20px; }

.document-shared, .document-size, .document-date {
  text-align: center;
  min-height: 20px; }

.document-actions {
  text-align: right; }
  .document-actions [class^="icon-"]:hover, .document-actions [class*=" icon-"]:hover {
    color: #fff; }

.vault h3.header-icons {
  color: #018da7;
  font-size: 20px;
  line-height: 50px;
  margin: 0; }
  .vault h3.header-icons [class^="icon-"], .vault h3.header-icons [class*=" icon-"] {
    float: left;
    margin: 0 20px 0 0; }

#filedrag {
  display: none;
  font-weight: bold;
  text-align: center;
  padding: 1em 0;
  margin: 1em 0;
  color: #555;
  border: 2px dashed #555;
  border-radius: 7px;
  cursor: default; }

#filedrag:hover, #filedrag.hover {
  color: #ff3030;
  border-color: #ff3030;
  border-style: solid;
  box-shadow: inset 0 3px 4px #888; }

#progress {
  display: block;
  height: 18px;
  padding: 3px;
  margin: 1px 0;
  border-radius: 9px;
  background: #f0f0f0; }
  #progress .current {
    background: #018da7;
    border-radius: 6px;
    height: 12px;
    min-width: 12px;
    width: 0; }
  #progress .failed {
    background: #ff3030;
    width: 100%; }

.hidden {
  display: none; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.document-edit-wrap {
  overflow: hidden;
  visibility: visible;
  transition: visibility 0s ease-in-out 0s; }
  .document-edit-wrap:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    margin: 2px 0 0;
    background: #f0f0f0; }
  .document-edit-wrap.closed:before {
    display: none; }
  .document-edit-wrap .document-edit {
    padding: 20px;
    margin-top: 0;
    transition: margin 0.25s linear 0s; }
    .document-edit-wrap .document-edit h4 {
      font-size: 14px;
      line-height: 20px;
      font-weight: bold; }
    .document-edit-wrap .document-edit label {
      color: #f0f0f0;
      font-size: 14px; }
    .document-edit-wrap .document-edit input[type="text"], .document-edit-wrap .document-edit input[type="email"] {
      border-radius: 5px;
      border: none;
      height: 36px;
      font-size: 13px;
      margin-left: 0; }
      .document-edit-wrap .document-edit input[type="text"][required], .document-edit-wrap .document-edit input[type="email"][required] {
        background: #fff; }
    .document-edit-wrap .document-edit input:invalid, .document-edit-wrap .document-edit input.invalid {
      box-shadow: 0px 0px 1px 1px red; }
    .document-edit-wrap .document-edit input:focus:invalid {
      box-shadow: 0px 0px 3px 2px red; }
    .document-edit-wrap .document-edit button {
      font-size: 13px;
      background: #f0f0f0;
      color: #018da7;
      float: right;
      padding: 12px 22px 11px; }
      .document-edit-wrap .document-edit button.loading:before {
        content: '\e93c';
        font-family: 'icomoon' !important;
        margin: 0 10px 0 0;
        animation: spin 1s infinite linear;
        display: inline-block; }
  .document-edit-wrap.closed {
    overflow: hidden;
    visibility: hidden;
    transition: visibility 0s ease-in-out 1s; }
    .document-edit-wrap.closed .document-edit {
      margin-top: -100%; }
  .document-edit-wrap .document-edit-share [class^="icon-"], .document-edit-wrap .document-edit-share [class*=" icon-"] {
    font-size: 14px; }
  .document-edit-wrap .document-edit-share .icon-remove {
    font-size: 16px;
    line-height: 36px; }
  .document-edit-wrap .document-edit-share label {
    position: absolute;
    margin: 7px 0 0 7px; }
  .document-edit-wrap .document-edit-share label > [class^="icon-"], .document-edit-wrap .document-edit-share label > [class*=" icon-"] {
    font-size: 16px;
    color: #018da7; }
  .document-edit-wrap .document-edit-share input[type="email"] {
    padding-left: 40px; }
  .document-edit-wrap .document-edit-delete {
    padding: 10px 0 0;
    text-align: right; }
  .document-edit-wrap a {
    font-size: 13px;
    font-weight: bold;
    color: #f0f0f0;
    font-weight: bold; }
    .document-edit-wrap a:hover {
      text-decoration: none; }
    .document-edit-wrap a > [class^="icon-"], .document-edit-wrap a > [class*=" icon-"] {
      font-size: 14px;
      padding: 0 8px 0 5px; }
  .document-edit-wrap .note {
    font-size: 12px;
    color: #f0f0f0;
    text-align: right;
    display: block; }

.logout {
  position: relative; }

#user-switch {
  display: inline-block; }

a.user-switch-name {
  border-bottom: 1px dashed #018da7;
  cursor: pointer; }
  a.user-switch-name:hover {
    text-decoration: none; }

.user-switch.loading {
  padding: 100px 0;
  text-align: center;
  font-size: 30px; }

.user-switch a {
  text-decoration: none; }

.user-switch {
  position: absolute;
  z-index: 1;
  top: 32px;
  right: 30px;
  width: 420px;
  background: #e6e6e6;
  border-radius: 5px;
  padding: 12px;
  text-align: left; }
  .user-switch::before {
    content: '';
    position: absolute;
    top: -12px;
    left: 60%;
    border: 6px solid transparent;
    border-bottom-color: #E6E6E6; }
  .user-switch .user-switch-header {
    border-bottom: 1px solid #fff;
    padding: 0 0 8px;
    margin: 0 0 8px; }
    .user-switch .user-switch-header h4 {
      font-weight: bold;
      text-transform: uppercase;
      color: #565a5c;
      line-height: 20px;
      margin: 0; }
    .user-switch .user-switch-header .search-clients label {
      float: right;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      margin: 0;
      width: 10%;
      text-align: center;
      cursor: pointer; }
    .user-switch .user-switch-header .search-clients label.icon-search.left {
      float: left; }
    .user-switch .user-switch-header .search-clients input[type="search"] {
      background: none;
      border: none;
      border-radius: none;
      text-overflow: ellipsis;
      box-shadow: none;
      margin: 0;
      padding: 0 1%;
      height: 20px;
      width: 78%;
      font-size: 12px;
      text-align: right;
      float: right; }
  .user-switch .user-switch-results {
    margin: 0 0 12px; }
    .user-switch .user-switch-results a.user-switch-client {
      color: #565a5c;
      font-weight: bold;
      line-height: 22px;
      height: 22px;
      float: left;
      width: 50%;
      padding: 0 5px; }
      .user-switch .user-switch-results a.user-switch-client:hover, .user-switch .user-switch-results a.user-switch-client:hover span {
        color: #018da7; }
      .user-switch .user-switch-results a.user-switch-client span.client-name {
        display: inline-block;
        width: 124px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 13px; }
      .user-switch .user-switch-results a.user-switch-client span.client-id {
        float: right;
        color: rgba(135, 135, 135, 0.6);
        font-size: 11px; }
  .user-switch .user-switch-footer {
    text-align: center;
    color: #878787; }
    .user-switch .user-switch-footer .user-switch-count {
      float: right;
      color: #565a5c;
      font-weight: bold; }

/*# sourceMappingURL=sass.css.map */
